var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-chip', _vm._g(_vm._b({
          staticClass: "ma-2",
          attrs: {
            "color": _vm.color,
            "text-color": "white"
          }
        }, 'v-chip', attrs, false), on), [_c('v-icon', [_vm._v(" mdi-alpha-c-circle ")]), _vm._v(" " + _vm._s(_vm.percentageValue) + " ")], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Confidence")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }