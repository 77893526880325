var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card-title', [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align-content": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.logo,
      expression: "logo"
    }],
    attrs: {
      "max-width": "100px",
      "max-height": "100px",
      "contain": "",
      "src": _vm.logo
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "auto",
      "align-self": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.title ? _vm.title : _vm.unavailable) + " "), _c('v-icon', {
    attrs: {
      "color": "yellow",
      "size": "30"
    }
  }, [_vm._v("mdi-star-circle")]), _c('a', {
    staticClass: "grey--text text-caption"
  }, [_vm._v(" (" + _vm._s(_vm.stars ? _vm.stars : _vm.unavailable) + ") ")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "md": "auto",
      "align-self": "center"
    }
  }, [_c('download-selector', {
    on: {
      "download": function (filetype) {
        return _vm.$emit('download', filetype);
      }
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-subtitle', [_c('v-card-text', [_vm._v(" " + _vm._s(_vm.description ? _vm.description : _vm.unavailable) + " ")]), _c('v-row', {
    staticClass: "mx-0 my-0",
    attrs: {
      "align-content": "center"
    }
  }, [_c('v-subheader', {
    staticClass: "subheader"
  }, [_vm._v("Last Release:")]), _c('v-row', {
    attrs: {
      "justify": "space-around",
      "align-content": "center"
    }
  }, [_c('v-col', [_vm._v(" " + _vm._s(_vm.lastRelease ? _vm.lastRelease : _vm.unavailable) + " ")])], 1)], 1), _c('v-row', {
    staticClass: "mx-0 my-0",
    attrs: {
      "align-content": "center"
    }
  }, [_c('v-subheader', {
    staticClass: "subheader"
  }, [_vm._v("Releases:")]), _c('v-row', {
    attrs: {
      "justify": "space-around",
      "align-content": "center"
    }
  }, [_c('v-col', [_vm._v(" " + _vm._s(_vm.totalReleases ? _vm.totalReleases : _vm.unavailable) + " ")])], 1)], 1), _c('v-row', {
    staticClass: "mx-0 my-0",
    attrs: {
      "align-content": "center"
    }
  }, [_c('v-subheader', {
    staticClass: "subheader"
  }, [_vm._v("Last Update:")]), _c('v-row', {
    attrs: {
      "justify": "space-around",
      "align-content": "center"
    }
  }, [_c('v-col', [_vm.lastModify ? _c('last-modify-chip', {
    attrs: {
      "value": _vm.lastModify
    }
  }) : _c('span', [_vm._v(" " + _vm._s(_vm.unavailable) + " ")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "mx-0 my-0",
    attrs: {
      "align-content": "center"
    }
  }, [_c('v-subheader', {
    staticClass: "subheader"
  }, [_vm._v("License:")]), _c('v-row', {
    attrs: {
      "justify": "space-around",
      "align-content": "center"
    }
  }, [_c('v-col', [_vm._v(" " + _vm._s(_vm.license) + " ")])], 1)], 1), _c('v-row', {
    staticClass: "mx-0 my-0",
    attrs: {
      "align-content": "space-around"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "href": _vm.repo,
      "target": "_blank",
      "disabled": !_vm.repo,
      "color": "#4078c0"
    }
  }, [_c('v-icon', [_vm._v("mdi-github")])], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "href": _vm.docker,
      "target": "_blank",
      "disabled": !_vm.docker,
      "color": "#0db7ed"
    }
  }, [_c('v-icon', [_vm._v("mdi-docker")])], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "href": _vm.notebooks,
      "target": "_blank",
      "disabled": !_vm.notebooks,
      "color": "#f37726"
    }
  }, [_c('v-icon', [_vm._v("mdi-notebook")])], 1), _c('v-spacer')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }