var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-select', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.toggleSelector,
      expression: "toggleSelector"
    }],
    attrs: {
      "dense": "",
      "items": _vm.downloadTypes,
      "menu-props": {
        value: _vm.toggleSelector
      }
    },
    on: {
      "change": _vm.changeHandler
    }
  }), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.toggleSelector = !_vm.toggleSelector;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-download")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }