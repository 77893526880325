var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card-title', {
    staticClass: "text-center justify-center"
  }, [_c('h1', [_vm._v("SOMEF Vider")])]), _c('somef-form', {
    on: {
      "submit": _vm.submitForm
    }
  }), _c('loading-dialog', {
    attrs: {
      "value": _vm.showLoadingDialog
    }
  }), _vm.showErrorDialog ? _c('server-response-dialog', {
    attrs: {
      "response": _vm.serverResponse,
      "showDialog": _vm.showErrorDialog
    }
  }) : _vm._e(), _vm.metadata ? _c('metadata-card', {
    attrs: {
      "threshold": _vm.formData.threshold,
      "metadata": _vm.metadata
    },
    on: {
      "download": _vm.downloadMetadata
    }
  }) : _vm._e(), _vm.showMetadataCard ? _c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "fab": "",
            "dark": "",
            "fixed": "",
            "bottom": "",
            "right": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.$vuetify.goTo(0);
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-arrow-up")])], 1)];
      }
    }], null, false, 2601864278)
  }, [_c('span', [_vm._v("Go to top")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }