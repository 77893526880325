var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card-subtitle', [_c('div', {
    staticClass: "info-row"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Provenance: Extracted with somef " + _vm._s(_vm.provenance_v) + " and schema " + _vm._s(_vm.provenance_s) + " ")])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }