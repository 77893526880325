var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panel', {
    attrs: {
      "id": _vm.id
    }
  }, [_c('v-expansion-panel-header', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-row', {
    staticClass: "mr-3",
    attrs: {
      "justify": "end"
    }
  }, [_vm.content.length === 1 ? _c('confidence-chip', {
    attrs: {
      "value": _vm.content[0].confidence,
      "threshold": _vm.threshold
    }
  }) : _vm._e(), _vm._l(_vm.extractionMethodList, function (method, index) {
    return _c('extract-method-chip', {
      key: index,
      attrs: {
        "value": method
      }
    });
  })], 2)], 1), _vm.content.length > 1 ? _c('v-expansion-panel-content', [_c('v-carousel', {
    attrs: {
      "height": "auto",
      "show-arrows": "",
      "hide-delimiter-background": ""
    }
  }, _vm._l(_vm.content, function (item, index) {
    return _c('v-carousel-item', {
      key: index
    }, [_c('v-container', {
      attrs: {
        "fluid": ""
      }
    }, [_c('v-row', {
      attrs: {
        "justify": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "11"
      }
    }, [_c('confidence-chip', {
      attrs: {
        "value": item.confidence,
        "threshold": _vm.threshold
      }
    }), _c('extract-method-chip', {
      attrs: {
        "value": item.extractionMethod
      }
    }), _c('editor', {
      attrs: {
        "mode": "viewer"
      },
      model: {
        value: item.result,
        callback: function ($$v) {
          _vm.$set(item, "result", $$v);
        },
        expression: "item.result"
      }
    })], 1)], 1), _c('br')], 1)], 1);
  }), 1)], 1) : _vm._e(), _vm.content.length === 1 ? _c('v-expansion-panel-content', [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('editor', {
    attrs: {
      "mode": "viewer"
    },
    model: {
      value: _vm.content[0].result,
      callback: function ($$v) {
        _vm.$set(_vm.content[0], "result", $$v);
      },
      expression: "content[0].result"
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }