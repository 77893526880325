var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('metadata-card-header', {
    attrs: {
      "logo": _vm.header.logo,
      "title": _vm.header.title,
      "stars": _vm.header.stars,
      "description": _vm.header.sortDescription,
      "last-modify": _vm.header.dateLastModify,
      "last-release": _vm.header.releaseLast,
      "total-releases": _vm.header.releaseTotal,
      "license": _vm.header.license,
      "docker": _vm.header.docker,
      "notebooks": _vm.header.notebooks,
      "repo": _vm.header.repoURL
    },
    on: {
      "download": function (filetype) {
        return _vm.$emit('download', filetype);
      }
    }
  }), _c('v-tabs', {
    attrs: {
      "show-arrows": ""
    },
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, _vm._l(_vm.panelItemsList, function (item) {
    return _c('v-tab', {
      key: item.title,
      on: {
        "click": function ($event) {
          return _vm.$vuetify.goTo('#' + item.title);
        }
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]);
  }), 1), _c('v-expansion-panels', {
    attrs: {
      "focusable": ""
    },
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, _vm._l(_vm.panelItemsList, function (panel) {
    return _c('metadata-card-panel', {
      key: panel.title,
      attrs: {
        "id": panel.title,
        "threshold": _vm.threshold,
        "title": panel.title,
        "content": panel.content
      }
    });
  }), 1), _c('metadata-card-footer', {
    attrs: {
      "provenance_v": _vm.footer.provenance_v,
      "provenance_s": _vm.footer.provenance_s,
      "provenance_d": _vm.footer.provenance_d
    },
    on: {
      "download": function (filetype) {
        return _vm.$emit('download', filetype);
      }
    }
  })], 1), _c('filter-dialog', {
    attrs: {
      "show-dialog": _vm.showFilterDialog
    },
    on: {
      "cancel": function ($event) {
        _vm.showFilterDialog = false;
      },
      "apply": function ($event) {
        _vm.showFilterDialog = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }