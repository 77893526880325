var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "300px"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" Filters ")]), _c('v-card-text', [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    attrs: {
      "dense": "",
      "no-gutters": ""
    }
  }, [_c('v-subheader', [_vm._v("Techniques")]), _c('v-chip-group', {
    attrs: {
      "multiple": "",
      "column": "",
      "active-class": "grey darken-4--text"
    },
    model: {
      value: _vm.currFilters.techniquesIdx,
      callback: function ($$v) {
        _vm.$set(_vm.currFilters, "techniquesIdx", $$v);
      },
      expression: "currFilters.techniquesIdx"
    }
  }, _vm._l(_vm.techniques, function (item) {
    return _c('extract-method-chip', {
      key: item,
      attrs: {
        "value": item
      }
    });
  }), 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "error",
      "text": ""
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.apply
    }
  }, [_vm._v(" Apply ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }