var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-app-bar', {
    attrs: {
      "app": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-breadcrumbs', {
    attrs: {
      "items": _vm.navBar,
      "divider": "|"
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.toggleDarkMode
    }
  }, [_c('v-icon', [_vm._v("mdi-theme-light-dark")])], 1)], 1)], 1)], 1), _c('v-main', [_c('router-view')], 1), _c('v-footer', [_c('v-spacer'), _c('v-row', {
    attrs: {
      "justify": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "align-self": "center"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://oeg.fi.upm.es/"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "65px",
      "max-heigh": "65px",
      "contain": "",
      "src": require("./assets/OEG-logo.png")
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "md": "auto",
      "align-self": "center"
    }
  }, [_c('a', {
    attrs: {
      "href": "http://fi.upm.es/"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "65px",
      "max-heigh": "65px",
      "contain": "",
      "src": require("./assets/ETSIINF-logo.png")
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "md": "auto",
      "align-self": "center"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://www.upm.es/"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "65px",
      "max-heigh": "65px",
      "contain": "",
      "src": require("./assets/UPM-logo.png")
    }
  })], 1)]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "align-self": "center"
    }
  }, [_vm._v(" 2021 — "), _c('a', {
    attrs: {
      "href": "https://github.com/SoftwareUnderstanding/SOMEF-Vider"
    }
  }, [_c('strong', [_vm._v("SOMEF Vider")])])]), _c('v-spacer')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }