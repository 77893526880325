var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "300"
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "text-center"
  }, [_vm._v(" We are analyzing your repository. "), _c('br'), _vm._v("Time may vary depending on its size. "), _c('br'), _c('br'), _vm._v("Please be patient. "), _c('v-progress-linear', {
    staticClass: "mb-0",
    attrs: {
      "indeterminate": "",
      "color": "blue"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }