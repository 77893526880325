var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "align-self": "center"
    }
  }, [_c('v-text-field', {
    attrs: {
      "id": "url-input",
      "placeholder": "https://github.com/KnowledgeCaptureAndDiscovery/somef/",
      "outlined": true,
      "rules": _vm.rules.urlRules,
      "required": "",
      "label": "GitHub URL"
    },
    on: {
      "keydown": _vm.autocomplete
    },
    model: {
      value: _vm.url,
      callback: function ($$v) {
        _vm.url = $$v;
      },
      expression: "url"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2",
      "align-self": "center",
      "md": "auto"
    }
  }, [_c('v-text-field', {
    attrs: {
      "id": "threshold-input",
      "hint": "Numeric value between 0 and 1",
      "placeholder": "0.7",
      "max": 1,
      "min": 0,
      "step": 0.1,
      "required": "",
      "rules": _vm.rules.thresholdRules,
      "type": "number",
      "outlined": true,
      "label": "Threshold"
    },
    on: {
      "keydown": _vm.autocomplete
    },
    model: {
      value: _vm.threshold,
      callback: function ($$v) {
        _vm.threshold = $$v;
      },
      expression: "threshold"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2",
      "md": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "height": "56px",
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" SUBMIT ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }